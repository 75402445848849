import React, { useEffect } from 'react';
// import { Button, Icon } from 'semantic-ui-react';
import './Feed.css';
import Post from './Post';
// import Header from './Header';
// import Footer from './Footer';
import { apiUrl } from './settings.js';
import { registerNotificationWorker } from './index.js';
import Layout from './layouts/Layout';

function Ad() {
    useEffect(() => {
        window.adsbygoogle = window.adsbygoogle || [];
        window.adsbygoogle.push({});
    }, []);

    return (
        <ins
            className='adsbygoogle'
            style={{ display: 'block' }}
            data-ad-format='fluid'
            data-ad-layout-key='-fb+5w+4e-db+86'
            data-ad-client='ca-pub-7275515812975070'
            data-ad-slot='2560281554'
        />
    );
}

class Feed extends React.Component {
    constructor(props) {
        super(props);

        this.nextFeed = apiUrl + '/feed/';
        this.lastPost = null;
        this.scrollLock = false;
        this.scrollTimeout = null;
        this.pageNum = null;
        this.hasAsked = false;

        this.state = {
            error: false,
        };
    }

    getFeed() {
        if (this.nextFeed) {
            fetch(this.nextFeed)
                .then((res) => res.json())
                .then(
                    (result) => {
                        const oldFeed = this.props.feed;
                        const oldIds = oldFeed.map((x) => x.id);

                        const toAdd = result.results.filter(
                            (x) => !oldIds.includes(x.id)
                        );

                        this.nextFeed = result.next;
                        if (result.next) {
                            const nextUrl = new URL(result.next);
                            this.pageNum = nextUrl.searchParams.get('page') - 1;

                            if (this.pageNum >= 2) {
                                console.log('fetched page', this.pageNum);
                                window.gtag('event', 'page_load', {
                                    event_category: 'feed',
                                    event_label: this.pageNum,
                                    value: 1,
                                });
                            }

                            this.hasAsked = false;
                        }

                        if (toAdd.length) {
                            this.props.addFeed(toAdd);
                        } else {
                            this.getFeed();
                        }
                    },
                    (error) => {
                        this.setState({ error: true });
                    }
                );
        }
    }

    componentDidMount() {
        this.lastPost = document.querySelector(
            '.feed-array > div:last-child .post'
        );

        if (!this.props.feed.length) {
            this.getFeed();
        }

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        this.lastPost = document.querySelector(
            '.feed-array > div:last-child .post'
        );
        this.scrollLock = false;
    }

    finishedScrolling = () => {
        if (!this.hasAsked && this.pageNum >= 3) {
            this.hasAsked = true;
            console.log('Trying to register...');
            registerNotificationWorker();
        }
    };

    handleScroll = () => {
        window.clearTimeout(this.scrollTimeout);

        this.scrollTimeout = setTimeout(this.finishedScrolling, 750);

        if (this.scrollLock) return;

        if (
            this.lastPost.offsetTop - window.pageYOffset - window.innerHeight <
            0
        ) {
            this.scrollLock = true;
            console.log('loading more from', this.nextFeed);
            this.getFeed();
        }
    };

    goToTop = () => {
        window.location = '/';
    };

    render() {
        const feed = this.props.feed;

        return (
            <Layout handleSidebar={this.props.handleSidebar} goToTop={true}>
                <div className='feed-container'>
                    <div className='feed-array'>
                        {feed.length ? (
                            feed
                                .filter((x) => !x.removed)
                                .map((x, i) =>
                                    i && i % 5 === 0 ? (
                                        <div key={x.id}>
                                            <Ad />
                                            <Post
                                                data={x}
                                                setVal={this.props.setVal}
                                            />
                                        </div>
                                    ) : (
                                        <div key={x.id}>
                                            <Post
                                                data={x}
                                                setVal={this.props.setVal}
                                            />
                                        </div>
                                    )
                                )
                        ) : (
                            <p>... جاري التحميل</p>
                        )}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Feed;

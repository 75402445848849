import React from 'react';
import { Icon } from 'semantic-ui-react';
import Logo from './logo.png';
//
class Footer extends React.Component {
    render() {
        return (
            <div className='footer'>
                <div className='feed-container'>
                    <p>
                        <a href='/'>
                            <img alt='site logo' src={Logo} className='logo' />
                        </a>
                    </p>
                    <p>
                        <a
                            href='https://twitter.com/qeeq_meme'
                            target='_blank'
                            rel='noopener noreferrer'
                            aria-label='link to our twitter'
                        >
                            <Icon name='twitter' size='big' />
                        </a>
                        <a
                            href='https://instagram.com/qeeq_meme/'
                            target='_blank'
                            rel='noopener noreferrer'
                            aria-label='link to our instagram'
                        >
                            <Icon name='instagram' size='big' />
                        </a>
                        <a
                            href='https://facebook.com/%D9%82%D9%8A%D9%82-101254651357138/'
                            target='_blank'
                            rel='noopener noreferrer'
                            aria-label='link to our facebook'
                        >
                            <Icon name='facebook' size='big' />
                        </a>
                    </p>
                    <p>كيك ٢٠١٩ ©</p>
                </div>
            </div>
        );
    }
}

export default Footer;

import React from 'react';
import { Button, Form, Card, Image } from 'semantic-ui-react';
import MediaSelector from '../MediaSelector';
import { postRequest, getOptions } from './funcUtils';

export default function ShareYoutubeURLForm({ setCurrentView }) {
    const [values, setValues] = React.useState({
        title: '',
        tags: '',
        link: '',
    });
    const [youtubeUrl, setYoutubeUrl] = React.useState(null);

    const handleChange = (prop) => (e) => {
        setValues({ ...values, [prop]: e.target.value });
    };

    const handleYoutubeURL = (prop) => (e) => {
        setValues({ ...values, [prop]: e.target.value });

        setYoutubeUrl(youtube_parser(e.target.value));
    };

    const addYoutubePost = (e) => {
        const options = getOptions('post', {
            title: values.title,
            link: values.link,
            tags: values.tags,
        });
        postRequest(options);
    };

    function youtube_parser(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return match && match[7].length == 11 ? match[7] : false;
    }

    return (
        <Form size='large'>
            <div className='buttonBackWrapper'>
                <Button
                    onClick={(e) => {
                        setCurrentView(
                            <MediaSelector setCurrentView={setCurrentView} />
                        );
                    }}
                    content='تراجع'
                    icon='right arrow'
                    labelPosition='right'
                />
            </div>
            <Image
                src='https://download.logo.wine/logo/YouTube/YouTube-Logo.wine.png'
                size='small'
                centered
            />
            <p className='headText'>شارك رابط يوتيوب</p>
            <p className='helpText'>شارك العنوان ورابط اليوتيوب</p>
            <Card fluid className='cardForm'>
                <Form.Field>
                    <label>العنوان</label>
                    <input
                        value={values.title}
                        onChange={handleChange('title')}
                        placeholder='إكتب هنا'
                    />
                </Form.Field>

                <Form.Field>
                    <label>العلامات</label>
                    <input
                        value={values.tags}
                        onChange={handleChange('tags')}
                        placeholder='مضحك ، أخبار ، حياة ، ترفية'
                    />
                </Form.Field>
                <Form.Field>
                    <label>الرابط</label>
                    <input
                        value={values.link}
                        onChange={handleYoutubeURL('link')}
                        placeholder='إكتب هنا'
                    />
                </Form.Field>
                {youtubeUrl && (
                    <div>
                        <div className='videoWrap alignToRight'>
                            <iframe
                                width='560'
                                height='315'
                                src={`https://www.youtube.com/embed/${youtubeUrl}`}
                            ></iframe>
                        </div>
                    </div>
                )}
            </Card>
            <div className='buttonSubmitWrapper'>
                <Button onClick={addYoutubePost} type='submit' color='green'>
                    تسليم
                </Button>
            </div>
        </Form>
    );
}

import { apiUrl } from "../../settings";

const api = apiUrl;

export const postRequest = (options, apiUrl = api) => {
  fetch(apiUrl + "/post/", options)
    .then((response) => {
      if (!response.ok) {
        const error = new Error(response.status);
        error.response = response;
        throw error;
      }
      return response.json();
    })
    .then((result) => {
      localStorage.setItem(result.id, JSON.stringify({ vote: "up" }));
      window.location = "/" + result.id;
    })
    .catch((error) => {
      const code = error.message;

      if (code == 413) {
        console.log(`Error ${code}, Message: ${error.message}`);
      } else if (code == 400) {
        error.response.json().then((result) => console.log("ERROR 400"));
      } else {
        console.log(`Unexpected Error: ${error}`);
      }
    });
};

export const getOptions = (method, body, headers = null) => {
  headers = headers ? headers : { "Content-Type": "application/json" };

	const key = localStorage.getItem('key');

	if (key) {
		headers['Authorization'] = 'Token ' + key;
	}

  return {
    method: method,
    headers: headers,
    body: JSON.stringify(body),
  };
};

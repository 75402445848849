import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { Button, Icon } from 'semantic-ui-react';

export default function Layout(props) {
    const goToTop = () => {
        window.location = '/';
    };
    return (
        <>
            <Header handleSidebar={props.handleSidebar} />

            {props.children}

            {props.goToTop !== undefined && (
                <div className='go-to-top'>
                    <Button
                        icon
                        color='green'
                        labelPosition='right'
                        onClick={() => goToTop()}
                    >
                        أذهب إلى الاعلى
                        <Icon name='up arrow' />
                    </Button>
                </div>
            )}

            <Footer />
        </>
    );
}

import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './Post.css';
import { Dropdown, Header, Menu, Icon, Image } from 'semantic-ui-react';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    RedditShareButton,
} from 'react-share';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import YouTube from 'react-youtube-embed';
import moment from 'moment';
import 'moment/locale/ar';
import QeeqIcon from './icon.png';
import { apiUrl, siteUrl } from './settings.js';

moment.locale('ar');

function CopyLink(props) {
    const [copied, setCopied] = useState(false);

    return (
        <CopyToClipboard text={props.url} onCopy={() => setCopied(true)}>
            <Menu.Item className='share'>
                <Icon name={copied ? 'check' : 'code'} size='large' />
            </Menu.Item>
        </CopyToClipboard>
    );
}
// my change
class Post extends React.Component {
    constructor(props) {
        super(props);

        this.data = this.props.data;
        this.id = this.data.id;

        const voted = localStorage.getItem(this.id) || false;
        this.props.setVal(this.id, 'voted', voted);

        this.state = {
            share: false,
            reported: false,
        };
    }

    downvote() {
        const vote = this.data.voted;
        let downvotes = this.data.downvotes;
        let upvotes = this.data.upvotes;

        if (vote == 'down') {
            return;
        }

        if (vote == 'up') {
            fetch(apiUrl + '/post/' + this.id + '/unupvote/');
            upvotes--;
        }

        fetch(apiUrl + '/post/' + this.id + '/downvote/');
        downvotes++;

        window.gtag('event', 'downvote', {
            event_category: 'post',
            event_label: this.id,
        });

        this.props.setVal(this.id, 'downvotes', downvotes);
        this.props.setVal(this.id, 'upvotes', upvotes);
        this.props.setVal(this.id, 'voted', 'down');
        localStorage.setItem(this.id, 'down');
    }

    upvote() {
        const vote = this.data.voted;
        let downvotes = this.data.downvotes;
        let upvotes = this.data.upvotes;

        if (vote == 'up') {
            return;
        }

        if (vote == 'down') {
            fetch(apiUrl + '/post/' + this.id + '/undownvote/');
            downvotes--;
        }

        fetch(apiUrl + '/post/' + this.id + '/upvote/');
        upvotes++;

        window.gtag('event', 'upvote', {
            event_category: 'post',
            event_label: this.id,
        });

        this.props.setVal(this.id, 'downvotes', downvotes);
        this.props.setVal(this.id, 'upvotes', upvotes);
        this.props.setVal(this.id, 'voted', 'up');
        localStorage.setItem(this.id, 'up');
    }

    openshare() {
        this.setState({ share: true });
    }

    report() {
        fetch(apiUrl + '/post/' + this.id + '/report/');
        this.setState({ reported: true });
    }

    render() {
        const data = this.data;
        const downvotes = data.downvotes;
        const upvotes = data.upvotes;
        const dateString = moment.unix(data.date).fromNow();
        const downvoted = data.voted === 'down';
        const upvoted = data.voted === 'up';
        const share = this.state.share;
        const shareUrl = (x) => siteUrl + '/' + data.id;
        const reported = this.state.reported;

        return (
            <div className='post'>
                <div className='post-header'>
                    {/* dropdown report */}

                    <Menu className='menu' text>
                        <Dropdown item icon='ellipsis horizontal'>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    className='report-option'
                                    icon='warning circle'
                                    text='الابلاغ عن منشور عشوائي'
                                    onClick={() => this.report()}
                                />
                                <Dropdown.Item
                                    className='report-option'
                                    icon='warning circle'
                                    text='الابلاغ عن منشور عنف'
                                    onClick={() => this.report()}
                                />
                                <Dropdown.Item
                                    className='report-option'
                                    icon='warning circle'
                                    text='الابلاغ عن منشور تعرّي'
                                    onClick={() => this.report()}
                                />
                            </Dropdown.Menu>
                        </Dropdown>

                        {reported && <Menu.Item>تم التبليغ</Menu.Item>}

                        <Menu.Item position='right'>
                            {dateString}
                            <Image
                                circular
                                className='post-logo'
                                src={QeeqIcon}
                            />
                        </Menu.Item>
                    </Menu>
                </div>

                {data.post_type === 'image' ? (
                    <Link to={'/' + data.id} className='post-link'>
                        <div className='img-container'>
                            <img src={data.url} />
                        </div>

                        <div className='post-title'>{data.title}</div>
                    </Link>
                ) : data.link.startsWith('https://www.youtube.com/watch?v=') ? (
                    <div className='post-link'>
                        <div className='img-container'>
                            <YouTube
                                id={data.link.replace(
                                    'https://www.youtube.com/watch?v=',
                                    ''
                                )}
                            />
                        </div>

                        <div className='post-title'>
                            <Link to={'/' + data.id}>{data.title}</Link>
                        </div>
                    </div>
                ) : data.post_type === 'text' ? (
                    <div className='post-text'>
                        <Link to={'/' + data.id} className='post-text-title'>
                            {data.title}
                        </Link>
                        <p>{data.text}</p>
                    </div>
                ) : (
                    <a
                        href={data.link}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='post-link'
                    >
                        <div className='img-container'>
                            <img src={data.url} />
                        </div>

                        <div className='post-title'>{data.title}</div>
                    </a>
                )}

                <div className='post-footer'>
                    <Menu className='menu' text fluid widths={4}>
                        <Menu.Item
                            className='share'
                            onClick={() => this.openshare()}
                            active={share}
                        >
                            مشاركة <Icon name='share alternate' />
                        </Menu.Item>
                        <Menu.Item
                            className='comments'
                            name='comments'
                            as={Link}
                            to={'/' + data.id}
                        >
                            {data.comments} <Icon name='comment' /> التعليقات
                        </Menu.Item>
                        <Menu.Item
                            className='downvote'
                            onClick={() => this.downvote()}
                            active={downvoted}
                        >
                            {downvotes} <Icon name='thumbs down' />
                        </Menu.Item>
                        <Menu.Item
                            className='upvote'
                            onClick={() => this.upvote()}
                            active={upvoted}
                        >
                            {upvotes} <Icon name='thumbs up' />
                        </Menu.Item>
                    </Menu>

                    {share && (
                        <Menu className='menu' text fluid widths={4}>
                            <CopyLink url={shareUrl()} />

                            <Menu.Item
                                className='share'
                                as={TwitterShareButton}
                                url={shareUrl()}
                            >
                                <Icon name='twitter' size='large' />
                            </Menu.Item>
                            <Menu.Item
                                className='share'
                                as={WhatsappShareButton}
                                url={shareUrl()}
                            >
                                <Icon name='whatsapp' size='large' />
                            </Menu.Item>
                            <Menu.Item
                                className='share'
                                as={FacebookShareButton}
                                url={shareUrl()}
                            >
                                <Icon name='facebook' size='large' />
                            </Menu.Item>
                        </Menu>
                    )}
                </div>
            </div>
        );
    }
}

export default Post;

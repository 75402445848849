import React from 'react';
import { Button, Form, Card, Icon, Divider, Image } from 'semantic-ui-react';
import MediaSelector from '../MediaSelector';
import { apiUrl } from '../../settings';
import { postRequest } from './funcUtils';

export default function UploadImageForm({ setCurrentView }) {
    const [values, setValues] = React.useState({
        title: '',
        tags: '',
        upload: '',
    });
    const [image, setImage] = React.useState('');

    const handleChange = (prop) => (e) => {
        setValues({ ...values, [prop]: e.target.value });
        console.log(values.upload);
    };

    const handleChangeImage = (prop) => (e) => {
        setValues({
            ...values,
            [prop]: { URL: e.target.value, file: e.target.files[0] },
        });

        var reader = new FileReader();

        reader.onload = (e) => {
            setImage(e.target.result);
        };

        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const fileRef = React.createRef();

    const upload = (e) => {
        const formData = new FormData();
        formData.append('upload', values.upload.file, values.upload.URL);
        formData.append('title', values.title);
        formData.append('tags', values.tags);

        const options = {
            method: 'POST',
            body: formData,
        };

        postRequest(options);
    };
    return (
        <Form size='large'>
            <div className='buttonBackWrapper'>
                <Button
                    onClick={(e) => {
                        setCurrentView(
                            <MediaSelector setCurrentView={setCurrentView} />
                        );
                    }}
                    content='تراجع'
                    icon='right arrow'
                    labelPosition='right'
                />
            </div>

            <p className='headText'>إرفع صورة</p>
            <p className='helpText'>أضف عنوان للصورة وإرفعها للتطبيق</p>
            <Card fluid className='cardForm'>
                <Form.Field>
                    <label>العنوان</label>
                    <input
                        value={values.title}
                        onChange={handleChange('title')}
                        placeholder='إكتب هنا'
                    />
                </Form.Field>
                <Form.Field>
                    <label>العلامات</label>
                    <input
                        value={values.tags}
                        onChange={handleChange('tags')}
                        placeholder='مضحك ، أخبار ، حياة ، ترفية'
                    />
                </Form.Field>

                <div className='uploadWrapper'>
                    <p className='helpText'>إختر الصورة</p>

                    <Divider horizontal>اضغط ادناه</Divider>

                    <input
                        type='file'
                        accept='image/*'
                        hidden
                        ref={fileRef}
                        onChange={handleChangeImage('upload')}
                    />

                    <div className='centerFlex'>
                        <button
                            onClick={() => fileRef.current.click()}
                            className='uploadInput'
                        >
                            <Icon
                                className='icon'
                                color='green'
                                name='images outline'
                            />
                        </button>
                    </div>
                    {image && (
                        <div className='imageContainer'>
                            <Image
                                className='previewImage'
                                src={image}
                                fluid
                                centered
                            />
                        </div>
                    )}
                </div>
            </Card>
            <div className='buttonSubmitWrapper'>
                <Button onClick={upload} type='submit' color='green'>
                    تسليم
                </Button>
            </div>
        </Form>
    );
}

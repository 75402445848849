import React from 'react';
import { Grid, Divider } from 'semantic-ui-react';
import SelectorButton from './SelectorButton';
import DiscussionForm from './postTypes/DiscussionForm';
import UploadImageForm from './postTypes/UploadImageForm';
import ShareNewsForm from './postTypes/ShareNewsForm';
import ShareYoutubeURLForm from './postTypes/ShareYoutubeURLForm';

export default function MediaSelector({ setCurrentView }) {
    return (
        <div className='mediaSelector'>
            <p className='headText'>أضف منشور جديد</p>
            <Divider horizontal>إختيار نوع المنشور</Divider>

            <Grid style={{ marginTop: '20px' }} columns={2}>
                <Grid.Row>
                    <Grid.Column className='columnSelector alignRight'>
                        <SelectorButton
                            icon='discussions'
                            text='إبدأ موضوع مناقشة'
                            handleClick={(e) =>
                                setCurrentView(
                                    <DiscussionForm
                                        setCurrentView={setCurrentView}
                                    />
                                )
                            }
                        />
                    </Grid.Column>
                    <Grid.Column className='columnSelector'>
                        <SelectorButton
                            icon='image outline'
                            text='ارفع صورة'
                            handleClick={(e) =>
                                setCurrentView(
                                    <UploadImageForm
                                        setCurrentView={setCurrentView}
                                    />
                                )
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column className='columnSelector alignRight'>
                        <SelectorButton
                            icon='newspaper'
                            text='شارك رابط'
                            handleClick={(e) =>
                                setCurrentView(
                                    <ShareNewsForm
                                        setCurrentView={setCurrentView}
                                    />
                                )
                            }
                        />
                    </Grid.Column>
                    <Grid.Column className='columnSelector youtube'>
                        <SelectorButton
                            icon='youtube'
                            text='شارك رابط يوتيوب'
                            handleClick={(e) =>
                                setCurrentView(
                                    <ShareYoutubeURLForm
                                        setCurrentView={setCurrentView}
                                    />
                                )
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

import React from 'react';
import './Post.css';
import { Form } from 'semantic-ui-react';
import Post from './Post';
import { apiUrl, siteUrl } from './settings.js';
import { Helmet } from 'react-helmet';

class FullPost extends React.Component {
    constructor(props) {
        super(props);

        this.id = this.props.id;

        this.state = {
            comments: false,
            error: false,
            text: '',
        };
    }

    componentDidMount() {
        if (!this.props.post) {
            console.log('fetching post');
            fetch(apiUrl + '/post/' + this.id + '/')
                .then((res) => res.json())
                .then(
                    (result) => {
                        this.props.addFeed([result]);
                        this.props.setVal(this.id, 'removed', true);
                    },
                    (error) => {
                        this.setState({ error: true });
                    }
                );
        }

        fetch(apiUrl + '/comment/' + this.id + '/')
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({ comments: result });
                },
                (error) => {
                    this.setState({ error: true });
                }
            );
    }

    handleChange = (e, { name, value }) => this.setState({ text: value });

    submitComment = (event) => {
        event.preventDefault();

        const body = JSON.stringify({
            post: this.id,
            text: event.target[0].value,
        });
        const options = {
            method: 'POST',
            body: body,
            headers: { 'Content-Type': 'application/json' },
        };

        fetch(apiUrl + '/comment/', options)
            .then((res) => res.json())
            .then(
                (result) => {
                    const prevComments = this.state.comments;
                    this.props.setVal(
                        this.id,
                        'comments',
                        prevComments.length + 1
                    );
                    this.setState({
                        comments: [...prevComments, result],
                        text: '',
                    });
                },
                (error) => {
                    this.setState({ error: true });
                }
            );
    };

    render() {
        const post = this.props.post;
        const text = this.state.text;
        const comments = this.state.comments;
        console.log(post);
        return (
            <div>
                {post ? (
                    <div>
                        <Helmet>
                            <meta charSet='utf-8' />
                            <title>{'كيك - ' + post.title}</title>
                            <meta
                                name='title'
                                content={'كيك - ' + post.title}
                            />
                            <meta name='description' content={post.text} />

                            {/* open graph */}
                            <meta
                                property='og:title'
                                content={'كيك - ' + post.title}
                            />
                            <meta
                                property='og:url'
                                content={`${siteUrl}/${post.id}`}
                            />
                            <meta property='og:image' content={post.url} />

                            {/* twitter */}
                            <meta
                                name='twitter:title'
                                content={'كيك - ' + post.title}
                            />
                            <meta name='twitter:card' content={post.text} />
                            <meta name='twitter:image' content={post.url} />
                        </Helmet>
                        <Post data={post} setVal={this.props.setVal} />
                    </div>
                ) : (
                    <p>... جاري التحميل</p>
                )}

                <div className='post-comments'>
                    <div className='submit-comment'>
                        <Form onSubmit={this.submitComment}>
                            <Form.TextArea
                                label=':شاركنا بتعليقك وتفاعل معنا'
                                value={text}
                                onChange={this.handleChange}
                            />
                            <Form.Button
                                className='submit-button'
                                color='green'
                            >
                                شارك الآن
                            </Form.Button>
                        </Form>
                    </div>

                    {comments.length ? (
                        comments.map((x, i) => (
                            <div key={i} className='comment'>
                                {x.text}
                            </div>
                        ))
                    ) : (
                        <p>لا تعليق الى الآن 🙁 شاركنا بتعليقك</p>
                    )}
                </div>
            </div>
        );
    }
}

export default FullPost;

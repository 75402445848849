import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Feed from './Feed';
import Modal from './Modal';
import Privacy from './Privacy';
import { Helmet } from 'react-helmet';
import QeeqSidebar from './components/QeeqSidebar';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.postLUT = {};

        this.state = {
            feed: [],
            showSidebar: false,
        };

        window.scrollTo(0, 0);

        console.log('Version 20');
    }

    addFeed = (toAdd) => {
        const feed = this.state.feed;
        toAdd.forEach((x, i) => {
            this.postLUT[x.id] = feed.length + i;
        });
        this.setState({ feed: feed.concat(toAdd) });
    };

    setVal = (id, key, val) => {
        const feed = this.state.feed;
        const index = feed.findIndex((x) => x.id == id);
        feed[index][key] = val;
        this.setState({ feed: feed });
    };

    handleSidebar = () => {
        this.setState((state) => ({
            showSidebar: !state.showSidebar,
        }));
    };

    render() {
        const feed = this.state.feed;

        return (
            <>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>كيك - Qeeq.fun</title>
                    <meta
                        name='description'
                        content='صفحة الانترنت الرئيسية في الوطن العربي'
                    />

                    <meta name='og:title' content='كيك - Qeeq.fun' />
                    <meta
                        property='og:image'
                        content='https://qeeq.fun/siteimage.jpg'
                    />

                    <meta name='twitter:card' content='summary_large_image' />
                    <meta
                        name='twitter:image'
                        content='https://qeeq.fun/siteimage.jpg'
                    />
                    <meta name='twitter:title' content='كيك - Qeeq.fun' />
                </Helmet>

                <Router>
                    <QeeqSidebar
                        visible={this.state.showSidebar}
                        handleSidebar={this.handleSidebar}
                    >
                        <Switch>
                            <Route
                                path='/privacy'
                                render={(props) => <Privacy />}
                            />

                            <Route
                                path='/:id'
                                render={(props) => (
                                    <Modal
                                        {...props}
                                        post={
                                            feed[
                                                this.postLUT[
                                                    props.match.params.id
                                                ]
                                            ]
                                        }
                                        addFeed={this.addFeed}
                                        setVal={this.setVal}
                                        handleSidebar={this.handleSidebar}
                                    />
                                )}
                            />
                        </Switch>

                        <Switch>
                            <Route path='/privacy' render={(props) => <hr />} />

                            <Route
                                path='/'
                                render={(props) => (
                                    <Feed
                                        {...props}
                                        feed={feed}
                                        addFeed={this.addFeed}
                                        setVal={this.setVal}
                                        handleSidebar={this.handleSidebar}
                                    />
                                )}
                            />
                        </Switch>
                    </QeeqSidebar>
                </Router>
            </>
        );
    }
}

export default App;

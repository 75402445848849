import React from 'react';
import { Icon } from 'semantic-ui-react';

export default function SelectorButton(props) {
    const { icon, text, handleClick } = props;
    return (
        <button onClick={handleClick} className='selectorButton'>
            <Icon className='icon' name={icon} />
            <p>{text}</p>
        </button>
    );
}

import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import MediaSelector from './MediaSelector';
export default function ModalContainer() {
    const [open, setOpen] = React.useState(false);
    const [text, setText] = React.useState('إضغط هنا لإضافة منشور جديد');
    const [currentView, setCurrentView] = React.useState('');

    React.useEffect(() => {
        setCurrentView(<MediaSelector setCurrentView={setCurrentView} />);
    }, []);

    return (
        <div className='modal--container'>
            <Modal
                className='innerModal'
                open={open}
                closeIcon={true}
                onClose={() => {
                    setCurrentView(
                        <MediaSelector setCurrentView={setCurrentView} />
                    );
                    setOpen(false);
                }}
                onOpen={() => setOpen(true)}
                trigger={
                    <Button
                        size='big'
                        color='green'
                        id='upload-button'
                        content={text}
                    />
                }
            >
                {currentView}
            </Modal>
        </div>
    );
}

import React from 'react';

class Privacy extends React.Component {
	render() {
		return (
			<div>
				<p>
					<br/>I.	النطاق والتطبيق
					<br/>النطاق والتطبيق تنطبق سياسة الخصوصية هذه ("سياسة الخصوصية") على الأشخاص في أي مكان من العالم الذي يدخلون ويستخدمون الخدمات الخاصة بنا ("المستخدمين") فضلاً عن الكابتن وشركات النقل الشريكة، أو أي أشخاص آخرين يستخدمون نظام قيق بموجب ترخيص (يشار إليهم مجتمعين بـ "الكابتن").
					<br/>II.	جمع المعلومات
					<br/>المعلومات التي تقدمها لن
					<br/>نحن نقوم بجمع المعلومات التي تقدمها لنا مباشرة من خلال وصولك أو استخدام للخدمات، مثلا، عندما تقوم بإنشاء أو تعديل الحساب الخاص بك، أو طلب خدمات حسب الطلب، أو الاتصال بدعم العملاء أو الاتصال بنا بطرق أخرى. يمكن أن تتضمن هذه المعلومات: اسمك، البريد الإلكتروني، رقم الهاتف، العنوان البريدي، صورة الملف الشخصي، طريقة الدفع، البيانات المالية ومعلومات بطاقة الائتمان، وأي معلومات أخرى تختار تقديمها لنا.
					<br/>الرجاء العلم بأننا لا نتعمد جمع المعلومات الشخصية من الأطفال ما دون سن 18 أو الاحتفاظ بها أو حفظها من خلال الخدمات، حيث أننا نطلب بأن يتعهد جميع المستخدمين لنا بأن عمرهم لا يقل عن 18 سنة.
					<br/>معلومات نقوم بجمعها من خلال استخدامك لخدماتنا
					<br/>عندما تقوم باستخدام خدماتنا، نقوم بجمع معلومات (أي معلومات يمكن استخدامها للاتصال بك مباشرة مثل الاسم الكامل والعنوان البريدي ورقم الهاتف ومعلومات بطاقة الائتمان/ الخصم، أو عنوان البريد الإلكتروني) ("المعلومات الشخصية)" والمعلومات الديموغرافية (أي المعلومات التي تقدمها أو التي نجمعها ولا تكون معلومات شخصية، يشمل ذلك، دون حصر، الرمز البريدي، البلد الأصلي، الجنس، اسم المستخدم، معلومات النظام، يشمل ذلك نوع جهاز الهاتف المحمول الذي تستخدمه، معرف الجهاز المميز (على سبيل المثال رقم IMEI للجهاز الخاص بك، عنوان الماك لواجهة الشبكة اللاسلكية للجهاز، أو رقم الهاتف المحمول المستخدم بواسطة الجهاز)، معلومات شبكة الهاتف المحمول، نظام تشغيل الهاتف، نوع متصفح الهاتف المحمول الذي تستخدمه، إعدادات المنطقة الزمنية، موقع الجهاز، عنوان بروتوكول IP، بيانات الرسائل القصيرة، معلومات التعاملات، العمر/ تاريخ الميلاد، معلومات سجل التصفح، معلومات سجل البحث، ومعلومات سجل التسجيل (" المعلومات الديموغرافية"، إلى جانب المعلومات الشخصية، "البيانات الشخصية").
					<br/>كما نستخدم أيضا تقنية نظام تحديد المواقع (GPS) لتحديد موقعك الحالي. بعض الخدمات الخاصة بنا التي تمكن الموقع تطلب بياناتك الشخصية للعمل في المستقبل. إذا كنت ترغب باستخدام خاصية معينة، سوف يطلب منك الموافقة على البيانات التي يجري استخدامها لهذا الغرض.
					<br/>معلومات نجمعها من مصادر أخرى
					<br/>ربما نحصل على معلومات أيضاً من مصادر أخرى وندمجها مع المعلومات الشخصية التي تلقيناها منك أو جمعناها من خلال خدماتنا. على سبيل المثال:
					<br/>o	إذا اخترت ربط أو إنشاء أو تسجيل الدخول إلى حساب قيق الخاص بك مع مزود خدمات الدفع أو خدمة وسيلة تواصل اجتماعية (على سبيل المثال الفيسبوك) أو إذا كنت ترتبط من خلال تطبيق أو موقع منفصل يستخدم واجهة معالجة التطبيق الخاصة بنا ( أو واجهة معالجة التطبيق التي نستخدمها)، يمكننا الحصول على معلومات عنك أو عن اتصالاتك من ذلك الموقع أو التطبيق
					<br/>o	إذا كانت جهة العمل الخاصة بك تستخدم واحداً من الحلول المؤسسية الخاصة بنا، يمكننا الحصول على معلومات عنك من جهة العمل الخاصة بك.
					<br/>o	عندما تطلب خدمات عند الطلب، يجوز للكابتن التابع لنا تزويدنا بتقييم للمستخدم بعد تقديم الخدمات لكم.
					<br/>o	إذا كنت تتفاعل أيضاً مع خدماتنا بصفة أخرى، على سبيل المثال ككابتن، ربما نقوم بدمج وربط تلك المعلومات بالمعلومات الشخصية التي قمنا بجمعها منكم بصفتكم مستخدما أو راكباً
					<br/>III.	استخدام البيانات الشخصية
					<br/>يمكننا استخدام المعلومات الشخصية التي تزودنا بها التي نجمعها منك للأغراض التالية:
					<br/>o	تزويد وصيانة وتحسين خدماتنا، يشمل ذلك على سبيل المثال، تسهيل الدفعات وإرسال الإيصالات وتقديم المنتجات والخدمات التي تطلبها (وإرسال معلومات ذات صلة)، وتطوير ميزات جديدة وتقديم دعم العملاء للمستخدمين والكابتن، وتطوير ميزات الأمان، ومنح الإذن للمستخدمين، وإرسال ترقيات وتحديثات المنتج والرسائل الإدارية.
					<br/>o	أداء العمليات والإدارة الداخلية، يشمل ذلك، على سبيل المثال، منع الاحتيال وإساءة استخدام خدماتنا، واستكشاف أخطاء البرمجيات وحلولها والمشاكل التشغيلية، وإجراء تحليل البيانات، والاختبار والبحث ومراقبة وتحليل اتجاهات الاستخدام والنشاط.
					<br/>o	إرسال أو تسهيل الاتصالات (i) بينك وبين الكابتن، مثل الأوقات المقدرة للوصول (ETAs)، أو (ii) بينك وبين جهة اتصال لك في اتجاهك فيما يتعلق استخدامك لميزات معينة مثل الإحالات أو الدعوات.
					<br/>o	إرسال معلومات لك نرى بأنها ذات أهمية بالنسبة لك، يشمل ذلك معلومات حول منتجات وخدمات قيق والعروض الترويجية والأخبار والأحداث الخاصة بقيق وشركات أخرى، حسب المسموح وطبقاً للقوانين المحلية المعمول بها، ومعالجة المسابقات ورهانات سباق الخيل أو أي مشاركات ترويجية أخرى والوفاء بأي جوائز ذات صلة؛
					<br/>o	إشعارك بشأن التغيرات على خدماتنا.
					<br/>o	السماح لك بالمشاركة في ميزات تفاعلية لخدماتنا.
					<br/>o	الحفاظ على خدماتنا آمنة ومؤمنة.
					<br/>o	تخصيص وتحسين الخدمات، يشمل ذلك تقديم الميزات أو الوصية بها والمحتوى والاتصالات الاجتماعية والإحالات والإعلانات.
					<br/>IV.	تخزين ونقل البيانات الشخصية
					<br/>أنت توافق على أننا نمتلك الحق في نقل البيانات الشخصية المبينة في سياسة الخصوصية هذه إلى ومن، ومعالجها وحفظها في الجمهورية الأيرلندية، والإمارات العربية المتحدة ودول أخرى، والبعض منها يكون نظام حماية البيانات فيها أقل من المنطقة التي تقيم فيها. في تلك الحالة، سوف نقوم باتخاذ التدابير اللازمة لحماية بياناتك الشخصية وفقاً لسياسة الخصوصية هذه. بتقديم البيانات الشخصية الخاصة بك، فإنك توافق على هذا النقل أو التخزين أو المعالجة. سوف نقوم باتخاذ كافة الخطوات اللازمة والمعقولة لضمان معالجة البيانات الخاصة بك على نحو آمن ووفقا لسياسة الخصوصية هذه.
					<br/>فيما يتعلق بالبيانات الشخصية المعالجة والمحفوظة والمنقولة من وإلى الجمهورية الأيرلندية، تكون قيق هي المتحكم بالبيانات لغرض الامتثال لقوانين حماية البيانات لعام 1988 و 2003 الصادرة في الجمهورية الأيرلندية ("القوانين").
					<br/>الممثل المكلف من طرفنا لغرض الامتثال للقوانين هو زين الكردي.
					<br/>للحفاظ على سلامة قواعد البيانات الخاصة بنا، ولأداء الخدمات على أساس مستمر نيابة عنك، ولأغراض البحث والتحليلات ولأغراض إحصائية ولضمان الامتثال للقوانين واللوائح التنظيمية المطبقة، نحن نحتفظ بالمعلومات الشخصية والمعلومات الديموغرافية المقدمة بواسطة المستخدمين والكابتن لفترة طويلة من الوقت. لن تكون قيق مسؤولة عن دقة المعلومات التي تقدمها، وستقوم بتعديل وتحديث معلوماتك الشخصية في قواعد البيانات الخاصة بنا بناء على طلبك. سوف نقوم بمسح أو أرشفة معلوماتك الشخصية من الاستخدام النشط إذا طلب ذلك بمقتضى قانون معين أو بطلب منك. بالدخول أو الوصول إلى الخدمات، فإنك بموجبه تضمن وتتعهد بأنك تفهم أن جميع المعلومات المقدمة بواسطة من خلال الخدمات أو خلاف ذلك إلى قيق يمكن استخدامها بواسطة قيق وفقا للقوانين المطبقة، وسياسة الخصوصية هذه وشروط وأحكام الاستخدام للعميل.
					<br/>V.	مشاركة البيانات الشخصية
					<br/>ربما نقوم بمشاركة البيانات الشخصية التي نجمعها عنك كما هو موضح في هذا البيان أو كما هو موضح في وقت جمع المعلومات أو مشاركتها، يشمل ذلك ما يلي:
					<br/>من خلال خدماتنا
					<br/>يمكننا مشاركة البيانات الشخصية الخاصة بك:
					<br/>o	مع الكابتن من تقديم الخدمات التي تطلبها. على سبيل المثال، نقوم بمشاركة اسمك ومواقع الاستلام و/ أو التسليم مع الكابتن.
					<br/>o	مع أطراف ثالثة لتقديم خدمة قمتم بطلبها من خلال شراكة أو عرض ترويجي مقدم بواسطة طرف ثالث أو بواسطتنا.
					<br/>o	مع أطراف ثالثة تختار أنت السماح لنا بمشاركة البيانات الشخصية الخاصة بك معها، على سبيل المثال، تطبيقات أو مواقع أخرى تندمج مع واجهة معالجة التطبيق (API) أو الخدمات الخاصة بنا، أو واجهة معالجة تطبيق أو خدمة نندمج معها؛ و
					<br/>o	مع جهة العمل الخاصة بك (أو كيان مماثل) وأي أطراف ثالثة لازمة مشاركة بواسطتنا أو بواسطة جهة العمل الخاصة بكم، إذا كنت مشاركاً في أي من الحلول المؤسسية الخاصة بنا.
					<br/>VI.	مشاركة هامة أخرى
					<br/>يمكننا مشاركة البيانات الشخصية الخاصة بك:
					<br/>o	مع الكيانات التابعة والفرعية لقيق.
					<br/>o	مع الوكلاء والاستشاريين وشركاء التسويق والإعلان ومزودي خدمات آخرين ممن يحتاجون إلى الوصول إلى تلك البيانات الشخصية لأداء عمل نيابة عنا؛ أو لتنفيذ أي عقد نبرمه معهم.
					<br/>o	استجابة لطلب أي معلومات بواسطة سلطة مختصة إذا كنا نرى بأن الكشف عن تلك المعلومات كان بموجب، أو خلاف ذلك، بمقتضى أي قانون أو لائحة تنظيمية أو إجراء قانوني معمول بها.
					<br/>o	مع الجهات المسؤولة المكلفة بإنفاذ القانون والسلطات الحكومية أو أطراف ثالثة أخرى لتنفي أو تطبيق شروط وأحكام الخاصة بك وللتحقيق في انتهاكات محتملة أو إذا كنا نرى بأن أعمالكم كانت مخالفة وشروط وأحكام الخدمة الخاصة بنا أو لحماية الحقوق والممتلكات وأمان قيق أو الغير؛
					<br/>o	مع أطراف ثالثة فيما يخص أو خلال المفاوضات بشأن أي عملية اندماج أو بيع لأصول الشركة أو توحيد أو إعادة هيكلة أو تمويل أو شراء حصة أو كامل شركتنا بواسطة أي شركة أخرى أو شرائنا لأي شركة أخرى.
					<br/>o	إذا قمنا خلاف ذلك بإشعارك وأنت وافقت على المشاركة؛
					<br/>o	مع أطراف ثالثة في نموذج مجمع و/ أو مجهول المصدر لا يمكن استخدامه بصورة معقولة لتحديد هويتك.
					<br/>VII.	خياراتك
					<br/>التسويق واختيار الرفض
					<br/>يمنح قيق المستخدمين والكابتن الفرصة لاختيار رفض اتصالات من طرفنا أو من شركائنا في الجانب الذي نطلب فيها معلومات حول الزائر. يكون لك الخيار في أن تطلب منا عدم استخدام بياناتك الشخصية لأغراض التسويق وحذفها من قاعدة بياناتنا، وعدم استلام اتصالات مستقبلية أو اعدم استخدام خدماتنا.
					<br/>الوصول إلى المعلومات
					<br/>تمنحك القوانين الحق في الوصول إلى المعلومات المحفوظة عنك. يمكنك ممارسة حقك بالوصول وفقاً لأحكام القانون. سيكون أي طلب وصول خاضعاً لرسوم قدرها 6.35 يورو (أو ما يعادله بالعملة المحلية أسعار الصرف السائدة) لاستيفاء التكاليف ال100 20095  100 20095    0     0  83037      0 --:--:-- --:--:-- --:--:-- 82695
					<br/>خاصة بنا لتزويدك بالمعلومات التي بحوزتنا عنك.
					<br/>التغييرات على سياسة الخصوصية هذه
					<br/>يمكننا تغيير سياسة الخصوصية هذه من حين لآخر. إذا قمنا بأي تغييرات جوهرية ملموسة على الطريقة التي نتعامل بها مع البيانات الشخصية الخاصة بك، أو على سياسة الخصوصية، سوف نقوم بتزويدكم بإشعار من خلال الخدمات أو بواسطة أي وسائل أخرى مثل البريد الإلكتروني. استخدامكم المستمر للخدمات بعد ذلك الإشعار يشكل موافقة من طرفكم على تلك التغييرات. نحن نشجعكم على المراجعة الدورية لسياسة الخصوصية للإطلاع على أحدث المعلومات حول ممارسة الخصوصية الخاصة بنا.
					<br/>VIII.	الاحتياطات الأمنية
					<br/>الملف الشخصي الخاص بك لدى قيق محمي بكلمة مرور بحيث يكون لك الحق ولموظفي قيق المخولين في الوصول إلى معلومات الحساب الخاص بك. لن يقوم موظف قيق وبصورة استباقية بالاتصال بك وطلب أي معلومات حساب شخصية، يشمل ذلك كلمة المرور الخاصة بك.
					<br/>يبذل قيق كل جهد مستطاع لضمان أمن وحماية بياناتك الشخصية في نظامه. هناك موظفون في قيق مكرسون للحفاظ على سياسة الخصوصية المنصوص عليها هنا وأي مبادرات خصوصية أخرى، والمراجعة الدورية لأمن الويب والتأكد من أن كل موظف لدى قيق على علم ويدرك الممارسات الأمنية. للأسف لا يمكن ضمان حماية أي بيانات تنقل عبر الإنترنت بنسبة 100%. نتيجة لذلك، لا يمكن لقيق ضمان أمان أي بيانات شخصية تنقلها أو ترسلها لها وأنت تقوم بذلك على عاتقك الشخصي. إذا كان لديك أي استفسارات حول هذه المسألة، يمكنك الرجوع إلى شروط وأحكام الاستخدام للعميل. إلى المدى المسموح به بمقتضى القانون المطبق، يخلي قيق مسؤوليته صراحة ضد أي مطالبة أو مسؤولية ربما تنشأ في حال حصول أي أطراف على البيانات الشخصية التي ترسلها إلى قيق.
					<br/>IX.	ملفات تعريف الارتباط
					<br/>يستخدم الموقع الإلكتروني الخاص بنا ملفات تعريف ارتباط تميزك عن المستخدمين الآخرين لموقعنا الإلكتروني. هذا يساعدنا في أن نقدم لك تجربة جيدة عندما تتصفح الموقع الإلكتروني الخاص بنا ويسمح لنا أيضا بتحسين الموقع الإلكتروني الخاص بنا والخدمات التي نقدمها. بالوصول إلى الموقع الإلكتروني وتصفحه، فإنك توافق على استخدامنا لملفات تعريف الارتباط
					<br/>
					<br/>X.	اتصلوا بنا
					<br/>إذا كان لديكم أي استفسارات أو ملاحظات أو طلبات متعلقة ببيان الخصوصية، أو إذا كان لديكم أي شكاوى أو اعتراضات فيما يتعلق بطريقة معالجة قيق لبياناتكم الشخصية، الرجاء الاتصال بنا على البريد الإلكتروني: therealqeeq@gmail.com 
				</p>
			</div>
		);
	}
}

export default Privacy;

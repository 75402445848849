import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import Logo from './logo.png';
import FacebookLogin from 'react-facebook-login';
import { apiUrl } from './settings.js';

import ModalContainer from './components/ModalContainer';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            key: localStorage.getItem('key') || '',
            visible: false,
        };
    }

    responseFacebook = (response) => {
        console.log('Got Facebook response');
        this.setState({ key: 'loading' });

        const body = JSON.stringify({ access_token: response.accessToken });
        const options = {
            method: 'POST',
            body: body,
            headers: { 'Content-Type': 'application/json' },
        };

        fetch(apiUrl + '/rest-auth/facebook/', options)
            .then((res) => res.json())
            .then(
                (result) => {
                    console.log('Good login');
                    localStorage.setItem('key', result.key);
                    this.setState({ key: result.key });
                },
                (error) => {
                    this.setState({ error: true });
                }
            );
    };

    logout = () => {
        localStorage.setItem('key', '');
        this.setState({ key: '' });
    };

    handleSibar = () => {
        this.props.handleSidebar();
        console.log(document.getElementById('overlay'));

        var feed = document.getElementById('overlay');

        feed.classList += 'webo';
    };

    render() {
        const key = this.state.key;

        return (
            <div>
                <div className='header'>
                    <div className='contact-button'>
                        {/* <Button
						  icon
						  size="small"
						  labelPosition="right"
						  onClick={() => {
							window.location.href = "mailto:therealqeeq@gmail.com";
						  }}
						>
						  تواصل معنا
						  <Icon name="mail" />
						</Button> */}
                        <Button
                            className='btnsidebar'
                            onClick={this.handleSibar}
                            icon
                        >
                            <Icon name='bars' />
                        </Button>
                    </div>

                    <Link to='/'>
                        <img alt='site logo' src={Logo} className='logo' />
                    </Link>
                </div>

				{/*<div className='facebook-login'>
                    {key.length ? (
                        <Button
                            icon
                            size='small'
                            labelPosition='right'
                            onClick={() => this.logout()}
                        >
                            تسجيل الخروج
                            <Icon name='cancel' />
                        </Button>
                    ) : (
                        <FacebookLogin
                            appId='2482201635386426'
                            fields='name,email,picture'
                            callback={(r) => this.responseFacebook(r)}
                            cssClass='ui facebook button'
                            icon={<Icon name='facebook' />}
                            textButton='تسجيل الدخول'
                        />
                    )}
                </div>*/}

                {/* <QeeqSidebar visible={this.state.visible} /> */}
                <ModalContainer />
            </div>
        );
    }
}

export default Header;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import './Post.css';
import FullPost from './FullPost';
// import Header from './Header';
// import Footer from './Footer';
import Layout from './layouts/Layout';

class Modal extends React.Component {
    goToTop = () => {
        this.props.history.replace('/');
    };

    render() {
        const post = this.props.post;
        const display = post ? 'block' : 'none';

        return (
            <div className='modal' style={{ display: display }}>
                <Layout handleSidebar={this.props.handleSidebar}>
                    <div className='back-to-memes'>
                        <Button
                            icon
                            basic
                            labelPosition='right'
                            onClick={() => this.goToTop()}
                        >
                            تصفح منشورات أخرى
                            <Icon name='right arrow' />
                        </Button>
                    </div>

                    <div className='feed-container'>
                        <FullPost
                            post={post}
                            id={this.props.match.params.id}
                            addFeed={this.props.addFeed}
                            setVal={this.props.setVal}
                        />
                    </div>
                </Layout>
            </div>
        );
    }
}

export default withRouter(Modal);

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import toUint8Array from "urlb64touint8array";
import { apiUrl, siteUrl } from "./settings.js";
import "./sass/styles.scss";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

// Our custom service worker:
// Copied from:
// https://serviceworke.rs/push-payload_index_doc.html

export function registerNotificationWorker() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("notificationWorker.js");
    navigator.serviceWorker.ready
      .then(function (registration) {
        return registration.pushManager
          .getSubscription()
          .then(async function (subscription) {
            if (subscription) {
              return subscription;
            }
            const response = await fetch(apiUrl + "/push/vapidPublicKey/");
            console.log(response);
            const vapidPublicKey = await response.text();
            console.log(vapidPublicKey);
            const convertedVapidKey = toUint8Array(vapidPublicKey);
            return registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: convertedVapidKey,
            });
          });
      })
      .then(function (subscription) {
        fetch(apiUrl + "/push/register/", {
          method: "post",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            subscription: subscription,
          }),
        });
      });
  }
}

import React from 'react';
import { Button, Icon, Menu, Sidebar } from 'semantic-ui-react';
import FacebookLogin from 'react-facebook-login';

import { Link } from 'react-router-dom';
import Logo from '../logo.png';
import { apiUrl } from '../settings.js';

export default function QeeqSidebar(props) {
    const { visible } = props;
    const [key, setKey] = React.useState(localStorage.getItem('key') || '');

    const responseFacebook = (response) => {
        console.log('Got Facebook response');
        setKey('loading');

        const body = JSON.stringify({ access_token: response.accessToken });
        const options = {
            method: 'POST',
            body: body,
            headers: { 'Content-Type': 'application/json' },
        };

        fetch(apiUrl + '/rest-auth/facebook/', options)
            .then((res) => res.json())
            .then(
                (result) => {
                    console.log('Good login');
                    localStorage.setItem('key', result.key);
                    setInterval(result.key);
                },
                (error) => {
                    // this.setState({ error: true });
                }
            );
    };

    const logout = () => {
        localStorage.setItem('key', '');
        setKey('');
    };

    return (
        <>
            <Sidebar
                as={Menu}
                animation='overlay'
                icon='labeled'
                onHide={() => {
                    props.handleSidebar();
                }}
                vertical
                // inverted
                direction='right'
                visible={visible}
                style={{ width: '200px' }}
            >
                <div>
                    <Menu.Item>
                        <Link to='/'>
                            <img
                                alt='site logo'
                                width='100px'
                                src={Logo}
                                className='logo'
                            />
                        </Link>
                    </Menu.Item>

                    {key.length ? (
                        <>
                            <Menu.Item as='a'>
                                <Icon name='home' />
                                <Link to='/' style={{ color: 'black' }}>
									الصفحة الرئيسية
                                </Link>
                            </Menu.Item>
                            <Menu.Item as='a'>
                                <Icon name='clone' />
                                <Link to='/' style={{ color: 'black' }}>
									منشوراتي
                                </Link>
                            </Menu.Item>
                            <Menu.Item as='a'>
                                <Icon name='flag' />
                                <Link to='/' style={{ color: 'black' }}>
									السجل
                                </Link>
                            </Menu.Item>
                            <Menu.Item as='a'>
                                <Icon name='bell' />
                                <Link to='/' style={{ color: 'black' }}>
									الاشعارات
                                </Link>
                            </Menu.Item>

                            <Menu.Item
                                as='a'
                                onClick={(e) => {
                                    logout();
                                }}
                            >
                                <Icon name='log out' />
								تسجيل الخروج
                            </Menu.Item>
                        </>
                    ) : (
                        <Menu.Item as='p'>
                            <FacebookLogin
                                appId='2482201635386426'
                                fields='name,email,picture'
                                callback={(r) => responseFacebook(r)}
                                cssClass='ui facebook button'
                                icon={<Icon name='facebook' />}
                                textButton='تسجيل الدخول'
                            />
                        </Menu.Item>
                    )}
                </div>
                <div>
                    <Button
                        icon
                        size='small'
                        labelPosition='right'
                        style={{ marginTop: '10px' }}
                        onClick={() => {
                            window.location.href =
                                'mailto:therealqeeq@gmail.com';
                        }}
                    >
                        تواصل معنا
                        <Icon name='mail' />
                    </Button>
                </div>
            </Sidebar>

            <Sidebar.Pusher id='overlay' dimmed={visible}>
                {props.children}
            </Sidebar.Pusher>
        </>
    );
}

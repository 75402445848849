import React from 'react';
import { Button, Form, Card } from 'semantic-ui-react';
import MediaSelector from '../MediaSelector';
import { postRequest, getOptions } from './funcUtils';

export default function ShareNewsForm({ setCurrentView }) {
    const [values, setValues] = React.useState({
        title: '',
        tags: '',
        link: '',
    });

    const handleChange = (prop) => (e) => {
        setValues({ ...values, [prop]: e.target.value });
    };

    const addNews = (e) => {
        const options = getOptions('post', {
            title: values.title,
            link: values.link,
            tags: values.tags,
        });
        postRequest(options);
    };
    return (
        <Form size='large'>
            <div className='buttonBackWrapper'>
                <Button
                    onClick={(e) => {
                        setCurrentView(
                            <MediaSelector setCurrentView={setCurrentView} />
                        );
                    }}
                    content='تراجع'
                    icon='right arrow'
                    labelPosition='right'
                />
            </div>
            <p className='headText'>شارك رابط</p>
            <p className='helpText'>شارك العنوان والرابط</p>
            <Card fluid className='cardForm'>
                <Form.Field>
                    <label>العنوان</label>
                    <input
                        value={values.title}
                        onChange={handleChange('title')}
                        placeholder='إكتب هنا'
                    />
                </Form.Field>

                <Form.Field>
                    <label>العلامات</label>
                    <input
                        value={values.tags}
                        onChange={handleChange('tags')}
                        placeholder='مضحك ، أخبار ، حياة ، ترفية'
                    />
                </Form.Field>
                <Form.Field>
                    <label>الرابط</label>
                    <input
                        value={values.link}
                        onChange={handleChange('link')}
                        placeholder='إكتب هنا'
                    />
                </Form.Field>
            </Card>

            <div className='buttonSubmitWrapper'>
                <Button onClick={addNews} type='submit' color='green'>
                    تسليم
                </Button>
            </div>
        </Form>
    );
}
